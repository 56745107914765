<ion-content class="ion-padding">
  <ion-fab slot="fixed" horizontal="end" vertical="top">
    <ion-fab-button translucent="true" (click)="close()">
      <ion-icon name="close"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <div *ngIf="step==1">
    <h1>
      {{ "wall-reset-page.complete-reset-question" | translate }}
    </h1>
    <ion-item>
      <ion-chip class="big-button" (click)="completeReset=true;step=2">
        <ion-label color="primary">{{ "wall-reset-page.complete-reset" | translate }}</ion-label>
      </ion-chip>
    </ion-item>
    <ion-item>
      <ion-chip class="big-button" (click)="onlyNewPhotos=true;step=2">
        <ion-label color="primary">{{ "wall-reset-page.new-holds" | translate }}</ion-label>
      </ion-chip>
    </ion-item>
    <ion-item>
      <ion-chip class="big-button" (click)="onlyNewPhotos=true;step=2">
        <ion-label color="primary">{{ "wall-reset-page.just-a-new-photo" | translate }}</ion-label>
      </ion-chip>
    </ion-item>
  </div>
  <div *ngIf="step==2">
    <h1>
      {{ (onlyNewPhotos ? "wall-reset-page.photo-for-all-walls-question": "wall-reset-page.all-walls-question") | translate }}
    </h1>
    <ion-item>
      <ion-chip slot="start" class="big-button" (click)="wallsToUpdate=walls;step=(onlyNewPhotos ? 6 : 4)">
        <ion-label color="primary">{{ "wall-reset-page.all" | translate }}</ion-label>
      </ion-chip>
      <ion-chip slot="end" class="big-button" (click)="wallsToUpdate=[];step=3">
        <ion-label color="primary">{{ "wall-reset-page.some" | translate }}</ion-label>
      </ion-chip>
    </ion-item>
  </div>
  <div *ngIf="step==3">
    <h1>{{ "wall-reset-page.which-walls" | translate }}</h1>
    <ion-list>
      <ion-item class="wall-image-item" *ngFor="let wall of walls">
        <img slot="start" width="80px" height="80px" [wall]="wall">
        <ion-checkbox slot="end" (ionChange)="wallSelected($event, wall)" justify="end">{{wall.wallName}}</ion-checkbox>
      </ion-item>
    </ion-list>
    <ion-item>
      <ion-chip class="big-button" (click)="step=(onlyNewPhotos ? 6 : 4)" slot="end">
        <ion-label color="primary">{{ "wall-reset-page.next" | translate }}</ion-label>
      </ion-chip>
    </ion-item>
  </div>
  <div *ngIf="step==4">
    <h1>{{ "wall-reset-page.approve-reset" | translate }}</h1>
    <h2>{{ "wall-reset-page.approve-reset-info" | translate }}</h2>
    <ion-item>
      <ion-chip slot="end" class="big-button" (click)="resetWalls()">
        <ion-label color="primary">{{ "wall-reset-page.yes-approve" | translate }}</ion-label>
      </ion-chip>
      <ion-chip slot="start" class="small-button" (click)="close()">
        <ion-label color="secondary">{{ "wall-reset-page.forget" | translate }}</ion-label>
      </ion-chip>
    </ion-item>
  </div>
  <div *ngIf="step==5">
    <h1>{{ "wall-reset-page.walls-where-updated" | translate }}</h1>
    <h2>{{ "wall-reset-page.upload-photos" | translate }}</h2>
    <ion-item>
      <ion-chip slot="start" class="big-button" (click)="step=6">
        <ion-label color="primary">{{ "wall-reset-page.now" | translate }}</ion-label>
      </ion-chip>
      <ion-chip slot="end" class="big-button" (click)="close()">
        <ion-label color="primary">{{ "wall-reset-page.no-later" | translate }}</ion-label>
      </ion-chip>
    </ion-item>
  </div>
  <div *ngIf="step==6">
    <h1 *ngIf="wallsToUpdate.length > 0">{{ "wall-reset-page.upload-photos-header" | translate }}</h1>
    <ion-list>
      <ion-item class="wall-image-item" *ngFor="let wall of wallsToUpdate">
        <img slot="start" width="80px" height="80px" [wall]="wall">
        <ion-label>{{wall.wallName}}</ion-label>
        <ion-button slot="end" (click)="uploadPhoto(wall)"><ion-label>{{ "wall-reset-page.upload-new-photo" | translate
            }}</ion-label></ion-button>
      </ion-item>
    </ion-list>
    <ion-item *ngIf="wallsToUpdate.length === 0">
      <h1>{{ "wall-reset-page.all-photos-updated" | translate }}</h1>
    </ion-item>
    <ion-item>
      <ion-chip class="big-button" (click)="close()" slot="end">
        <ion-label color="primary">{{ "wall-reset-page.finish" | translate }}</ion-label>
      </ion-chip>
    </ion-item>
  </div>
</ion-content>