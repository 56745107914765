<ion-header>
  <ion-toolbar>
    <ion-title>4x4 {{'four-by-four-page.on' | translate}} {{boulderList?.name}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()" fill="clear">
        <ion-icon name="close" slot="start"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content *ngIf="boulders && boulderList else loading"
  [ngClass]="{
            'default-bg': !timerService.isRunning(),
            'hang-bg' : timerService.isRunning() && timerService.tickEvent.taskPhase==='work',
            'rest-bg' : timerService.isRunning() && timerService.tickEvent.taskPhase==='rest',
            'prepare-bg' : timerService.isRunning() && timerService.isRunning() && timerService.tickEvent.taskPhase==='prep'
         }">
  <ion-item class="volume-bar">
    <ion-icon slot="end" [color]="audioOn ? 'primary' : 'secondary'" name="volume-medium-outline"></ion-icon>
    <ion-toggle slot="end" [(ngModel)]="audioOn" (ionChange)="volumeChanged()"></ion-toggle>
  </ion-item>
  <br><br>
  <p class="repetition" *ngIf="timerService.tickEvent.taskPhase!=='prep'">
    {{timerService.taskEvent.batchIndex + 1}}/{{workout.timerConfig.numSets}} {{'four-by-four-page.repetition' | translate}}
  </p>
  <p *ngIf="timerService.tickEvent.taskPhase==='prep'" class="stage">
    {{'four-by-four-page.prepare' | translate}}
  </p>
  <p class="remaining-time">
    {{timerService.tickEvent.remainingTime| date:'mm:ss'}}
  </p>
  <p class="buttons">
    <ion-chip (click)="reset()">
      <ion-icon color="secondary" name="refresh-outline"></ion-icon>
      <ion-label color="secondary">{{'four-by-four-page.reset' | translate}}</ion-label>
    </ion-chip>
    <ion-chip class="play-chip" (click)="toggleStartPause()">
      <ion-icon size="large" name="{{timerService.isRunning()?'pause':'play'}}-outline"></ion-icon>
      <ion-label>{{timerService.isRunning() ? ('four-by-four-page.stop' | translate) : ('four-by-four-page.start' | translate)}}</ion-label>
    </ion-chip>
  </p>
  <p *ngIf="timerService.tickEvent.taskPhase==='rest'" class="rest">
    {{'four-by-four-page.relax' | translate}}
  </p>
  <div class="wall-container" *ngIf="timerService.tickEvent.taskPhase!=='rest'">
    <p class="boulder">
      {{'four-by-four-page.current-boulder' | translate}} ({{currentBoulderIndex() + 1 }}/{{workout.timerConfig.numReps}}): {{getCurrentBoulder().boulderName}}
    </p>
    <app-wall-image
      [editMode]="false"
      [holds]="getCurrentBoulder().holds"
      [wallImage]="getCurrentBoulder().wallImage">
    </app-wall-image>
  </div>
</ion-content>
<ng-template #loading>
  <loader></loader>
</ng-template>
