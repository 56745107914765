<ion-header>
  <ion-toolbar>
    <ion-title>{{gymToEdit ? ("create-gym-page.edit-gym" | translate) : "create-gym-page.create-gym" | translate}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()" fill="clear">
        <ion-icon name="close" slot="start"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <p *ngIf="!gymToEdit" [innerHTML]='"create-gym-page.create-gym-info" | translate'></p>
  <p *ngIf="!gymToEdit" [innerHTML]='"create-gym-page.create-wall-info" | translate'></p>
  <form *ngIf="newGymFormGroup" [formGroup]="newGymFormGroup">
    <ion-list>
      <ion-item class="input-field">
        <ion-input [label]="'create-gym-page.name-of-gym' | translate" labelPlacement="floating" fill="outline" type="text" [formControl]="name"
                   data-cy="gym-name"></ion-input>
      </ion-item>
      <ion-item class="input-field">
        <ion-input [label]="'create-gym-page.city' | translate" type="text" fill="outline" labelPlacement="floating"
                   [formControl]="city" data-cy="gym-city"></ion-input>
      </ion-item>
      <ion-item class="input-field">
        <ion-input [label]="'create-gym-page.country' | translate" type="text" fill="outline" labelPlacement="floating"
                   [formControl]="country"
                   data-cy="gym-country"></ion-input>
      </ion-item>
      <ion-item>

      </ion-item>
      <ion-item class="input-field">
        <ion-textarea [helperText]="'create-gym-page.gym-rules-placeholder' | translate"
                      [label]="'create-gym-page.gym-rules' | translate"
                      fill="outline" labelPlacement="floating"
                      [formControl]="gymRules" data-cy="gym-rules"></ion-textarea>
      </ion-item>
      <ion-item>
        <ion-toggle slot="end" justify="end" (ionChange)="isPrivateChanged($event)" labelPlacement="start"
                    [checked]="isPrivate">{{"create-gym-page.private-gym" | translate}}
        </ion-toggle>
      </ion-item>
      <ion-item *ngIf="isPrivate">
        <ion-icon slot="start" name="alert-circle-outline"></ion-icon>
        {{"create-gym-page.private-gym-note" | translate}}
      </ion-item>
      <br>
    </ion-list>
    <p *ngIf="filledButInvalid()" class="form-error">
      {{"create-gym-page.check-your-input" | translate}}
    </p>
    <ion-button id="addGymButton" [disabled]="!isValid()" expand="full" data-cy="save"
                (click)="gymToEdit ? saveChanges() : checkIfThereAreSimilarGyms()">
      <ion-icon slot="start" [name]="gymToEdit ?'create':'add-circle'"></ion-icon>
      {{gymToEdit ? ("create-gym-page.save" | translate) : "create-gym-page.add" | translate}}
    </ion-button>
  </form>
</ion-content>
