import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { WallImage } from '../../model/wall-image';
import { Hold, HoldType } from '../../common/docs';
import Konva from 'konva';
import { Platform } from '@ionic/angular';
import { HoldDetectionService } from '../../services/hold-detection.service';
import { ImageService } from '../../services/image.service';
import { WallImageComponentService } from './wall-image.component.service';
import Vector2d = Konva.Vector2d;
import { NGXLogger } from 'ngx-logger';
import pako from 'pako';
import { create } from 'cypress/types/lodash';

const zoomScaleFactor = 1.2;
const minZoom = 0;
const maxZoom = 2;
const paddingAroundImage = 40;
const settledCircleColor = 'rgb(254, 220, 83)';
const activeCircleColor = 'rgb(239, 75, 137)';
const sqrt2 = 1.414;


@Component({
  selector: 'app-wall-image',
  templateUrl: './wall-image.component.html',
  styleUrls: ['./wall-image.component.scss'],
})
export class WallImageComponent implements OnInit, OnDestroy, AfterViewInit {

  @Output() onImageTouched: EventEmitter<any> = new EventEmitter();
  @Output() onHoldsChanged: EventEmitter<Hold[]> = new EventEmitter();

  wallImage: WallImage = null;
  currentHoldType: HoldType;

  @Input()
  editMode: boolean;

  @Input('holds')
  public holds: Hold[] = [];

  @ViewChild('wallimagecontainer', { read: ElementRef, static: true }) container;
  // will be adjusted relative to image size once loaded
  private circleStrokeWidth = 10;
  private minHoldRadius = 10;
  private maxHoldRadius = 500;
  private iconFontSize = 50;
  private stage: Konva.Stage;
  private image: Konva.Image;
  private pic = new Image();
  activeHold: HoldElement;
  private holdElements: Map<String, HoldElement> = new Map();
  imageInitialized = false;
  private touchState: TouchState = new TouchState();
  private isHorizontal = false;
  private _lastHoldRadiusUsed = 100;

  private static changeHoldType(hold: HoldElement, holdType: HoldType) {
    hold.holdType = holdType;
  }


  constructor(
    private logger: NGXLogger,
    private holdDetectionService: HoldDetectionService,
    private service: WallImageComponentService,
    private changeDetectorRef: ChangeDetectorRef,
    private imageService: ImageService) {
  }

  ngAfterViewInit(): void {
    this.loadImage();
  }

  ngOnInit() {
  }

  @Input('wallImage')
  set setWallImage(wallImage: WallImage) {
    const imageOverwritten = this.wallImage !== null;
    this.imageInitialized = false;
    this.wallImage = wallImage;
    if (imageOverwritten) {
      this.loadImage();
    }
  }

  private loadImage() {
    this.service.clear();
    this.pic.onload = () => this.onImageLoaded();
    this.imageService.wallImagePath(this.wallImage).then(imageSrc => {
      this.pic.src = imageSrc;
      this.syncHolds();
    });
  }

  private initStage() {
    this.changeDetectorRef.detectChanges();
    this.stage = new Konva.Stage({
      container: 'wallimagecontainer',
      width: this.container.nativeElement.offsetWidth,
      height: this.container.nativeElement.offsetHeight,
      draggable: true
    });
    this.stage.on('wheel', (e) => this.onWheelScroll(e));
    this.stage.on('touchstart', (e) => this.onTouchStart(e));
    this.stage.on('touchmove', (e) => this.onTouchMove(e));
    this.stage.on('touchend', () => this.onTouchEnd());

    // limit dragging of the image such that it cannot leave the screen and touches the screen
    // borders for its widest extension
    this.stage.dragBoundFunc(pos => {
      // kind of epic everything ...
      const stageXRightMax = this.stage.width() - this.image.width() * this.stage.scaleX();
      const stageYBottomMax = this.stage.height() - this.image.height() * this.stage.scaleY();
      if (this.isHorizontal) {
        const screenFilled = stageYBottomMax < 0;
        const y = screenFilled
          ? Math.min(paddingAroundImage, -paddingAroundImage + Math.max(pos.y, stageYBottomMax))
          : Math.max(paddingAroundImage, Math.min(pos.y, stageYBottomMax));
        return {
          x: Math.min(0, Math.max(pos.x, stageXRightMax)),
          y: y
        };
      } else {
        const screenFilled = stageXRightMax < 0;
        const x = screenFilled
          ? Math.min(paddingAroundImage, -paddingAroundImage + Math.max(pos.x, stageXRightMax))
          : Math.max(0, Math.min(pos.x, stageXRightMax));
        return {
          x: x,
          y: Math.min(paddingAroundImage, Math.max(pos.y, stageYBottomMax)),
        };
      }
    });

    this.service.maskRect.on('click tap', () => this.onImageClicked());

    this.syncHolds();
    this.stage.add(this.service.imageLayer);
    this.stage.add(this.service.holdsLayer);
    this.stage.batchDraw();

    this.initializeCanvasSize();
  }

  private onImageLoaded() {
    this.initStage();

    this.image = new Konva.Image({ image: this.pic });
    this.isHorizontal = this.image.width() > this.image.height();
    const maxImageDimension = Math.max(this.image.width(), this.image.height());
    this.circleStrokeWidth = maxImageDimension / 250;
    this.minHoldRadius = maxImageDimension / 100;
    this.maxHoldRadius = maxImageDimension / 10;
    this._lastHoldRadiusUsed = maxImageDimension / 90;
    this.iconFontSize = maxImageDimension / 30;

    if (this.editMode) {
      // scale stage to fit image to canvas and center image
      const newScale = this.isHorizontal ? this.stage.width() / this.image.width() : this.stage.height() / this.image.height();
      this.stage.scale({ x: newScale, y: newScale });
      this.stage.position({
        x: (this.stage.width() - this.image.width() * newScale) / 2,
        y: (this.stage.height() - this.image.height() * newScale) / 2
      });
    } else {
      this.zoomToBoulder();
    }

    this.service.maskRect.width(this.image.width());
    this.service.maskRect.height(this.image.height());

    this.service.imageLayer.add(this.image);
    this.service.imageLayer.batchDraw();
    this.stage.batchDraw();

    //this.debugMask();

    this.imageInitialized = true;
  }

  private debugMask() {
    fetch('assets/d5g30l2IyIIAX1B.holds.mask')
      .then(res => res.arrayBuffer())
      .then(buffer => pako.inflate(buffer))
      .then(arr => {
        console.log('array ', arr.filter(h => h > 0));
        const dataView = new DataView(arr.buffer)
        const holds = new Uint16Array(dataView.buffer, dataView.byteOffset, dataView.byteLength / Uint16Array.BYTES_PER_ELEMENT)
        const canvas = document.createElement('canvas');
        canvas.width = this.image.width();
        canvas.height = this.image.height();
        const context = canvas.getContext('2d');
        const image = new Konva.Image({
          image: canvas,
          width: this.image.width(),
          height: this.image.height()
        });
        this.service.imageLayer.add(image);
        const imageData = context.createImageData(canvas.width, canvas.height);
        for (let i = 0; i < holds.length; ++i) {
          // show boulder
          imageData.data[i * 4 + 0] = holds[i] > 0 ? 255 : 0 // red
          // show all holds
          // imageData.data[i * 4 + 0] = holds[i] > 0 ? 255 : 0 // red
          imageData.data[i * 4 + 1] = 0 // green
          imageData.data[i * 4 + 2] = 0 // blue
          imageData.data[i * 4 + 3] = 150 // alpha
        }
        context.putImageData(imageData, 0, 0);
        this.stage.batchDraw();
      })
      .catch(err => console.log(err))
  }

  private zoomToBoulder() {
    // padding around boulder
    const padding = 150;
    // box around boulder
    const minX = Math.min.apply(Math, this.holds.map(h => h.centerX)) - padding;
    const maxX = Math.max.apply(Math, this.holds.map(h => h.centerX)) + padding;
    const minY = Math.min.apply(Math, this.holds.map(h => h.centerY)) - padding;
    const maxY = Math.max.apply(Math, this.holds.map(h => h.centerY)) + padding;
    const boulderWidth = maxX - minX;
    const boulderHeight = maxY - minY;
    // how much do we need to zoom/scale to fit boulder to screen width/height
    const boulderScale = Math.min(this.stage.height() / boulderHeight, this.stage.width() / boulderWidth);
    // position stage to the center of the boulder
    this.stage.position({
      x: (this.stage.width() - maxX * boulderScale - (this.stage.width() - boulderWidth * boulderScale) / 2),
      y: (boulderWidth > boulderHeight || boulderScale * this.image.height() < this.stage.height()
        ? (this.stage.height() - this.image.height() * boulderScale) / 2
        : -minY * boulderScale)
    });
    this.stage.scale({ x: boulderScale, y: boulderScale });
  }

  removeActiveHold() {
    if (!this.activeHold) {
      return;
    }
    this.holdElements.delete(this.activeHold.group.id());
    this.activeHold.group.remove();
    this.activeHold = undefined;
    this.currentHoldType = 'hold';
    this.stage.batchDraw();
    this.notifyListener();
  }

  private getCurrentHolds(): Array<Hold> {
    return Array.from(this.holdElements.values()).map((h: HoldElement) => {
      return {
        centerX: h.center.x,
        centerY: h.center.y,
        radius: h.radius,
        holdType: h.holdType
      };
    });
  }

  /**
   * syncs the underlying holds model with the image elements. this should only happen when the holds have changed from the outside and
   * overwrites any current changes in the view.
   */
  private syncHolds() {
    this.holdElements.forEach(h => h.group.remove());
    this.holdElements.clear();
    this.activeHold = undefined;
    this.currentHoldType = this.holds.length === 0 ? 'double-start' : 'hold';
    this.holds.map(h => {
      const hold = this.createHold(h);
      hold.setSettled();
      return hold;
    }).forEach(h => {
      this.holdElements.set(h.group.id(), h);
      this.service.maskAndHoldsGroup.add(h.group);
    });
    if (this.imageInitialized) {
      this.stage.batchDraw();
    }
  }

  private initializeCanvasSize() {
    const self = this;

    function resizeCanvas() {
      const height = self.container.nativeElement.offsetHeight;
      const width = self.container.nativeElement.offsetWidth;
      self.stage.height(height > 0 ? height : window.innerHeight);
      self.stage.width(width > 0 ? width : window.innerWidth);
      self.stage.batchDraw();
    }

    window.addEventListener('resize', resizeCanvas, false);
    resizeCanvas();
  }

  private onWheelScroll(e: { evt: WheelEvent }) {
    this.onImageTouched.emit();
    e.evt.preventDefault();
    if (this.activeHold) {
      const newRadius = this.activeHold.radius - e.evt.deltaY / 10;
      this.setActiveHoldRadius(newRadius);
      return;
    }
    const oldScale = this.stage.scaleX();
    const newScale = e.evt.deltaY < 0 ? oldScale * zoomScaleFactor : oldScale / zoomScaleFactor;
    this.zoomImage(this.stage.getPointerPosition(), newScale);
  }


  private onTouchStart(e: { evt: TouchEvent }) {
    this.onImageTouched.emit();
    this.touchState.hasMoved = false;
    const touch1 = e.evt.touches[0];
    const touch2 = e.evt.touches[1];
    if (!touch1 || !touch2) {
      return;
    }
    this.stage.draggable(false);
    if (this.activeHold) {
      this.touchState.startRadius = this.activeHold.radius;
    }
    this.touchState.startZoom = this.stage.scaleX();
    this.touchState.startPinchPoint = {
      x: (touch1.clientX + touch2.clientX) / 2,
      y: (touch1.clientY + touch2.clientY) / 2
    };
    this.touchState.startPinchDist = getDistance({ x: touch1.clientX, y: touch1.clientY }, {
      x: touch2.clientX,
      y: touch2.clientY
    });
  }

  private onTouchMove(e: { evt: TouchEvent }) {
    this.onImageTouched.emit();
    if (!this.touchState.startPinchPoint) {
      return;
    }
    this.touchState.hasMoved = true;
    const touch1 = e.evt.touches[0];
    const touch2 = e.evt.touches[1];
    if (touch1 && touch2) {
      const dist = getDistance({ x: touch1.clientX, y: touch1.clientY }, { x: touch2.clientX, y: touch2.clientY });
      if (this.activeHold) {
        const newRadius = this.touchState.startRadius * dist / this.touchState.startPinchDist;
        this.setActiveHoldRadius(newRadius);
        return;
      }
      const newScale = this.touchState.startZoom * dist / this.touchState.startPinchDist;
      this.zoomImage(this.touchState.startPinchPoint, newScale);
    }
  }

  private onTouchEnd() {
    this.stage.draggable(true);
    this.touchState.lastPinchDist = undefined;
    this.touchState.startZoom = undefined;
    this.touchState.startPinchPoint = undefined;
  }

  private async onImageClicked() {
    this.changeDetectorRef.detectChanges();
    this.stage.height(this.container.nativeElement.offsetHeight);
    this.stage.width(this.container.nativeElement.offsetWidth);
    this.stage.batchDraw();
    this.onImageTouched.emit();
    if (!this.editMode || this.touchState.hasMoved) {
      return;
    }
    this.disableActiveHold();
    await this.addHoldAndMakeActive();
    this.stage.batchDraw();
  }

  private onHoldClicked(ev) {
    if (!this.editMode) {
      return;
    }
    const clickedCircle = ev.target as any;
    const parent: Konva.Group = clickedCircle.parent;
    const clickedCircleHole: HoldElement = this.holdElements.get(parent.getAttrs().id);
    if (this.activeHold && this.activeHold === clickedCircleHole) {
      this.disableActiveHold();
    } else if (clickedCircleHole) {
      this.disableActiveHold();
      clickedCircleHole.setActive();
      this.activeHold = clickedCircleHole;
      this.currentHoldType = clickedCircleHole.holdType;
    }
    this.stage.batchDraw();
  }

  private setActiveHoldRadius(newRadius: number) {
    if (newRadius < this.minHoldRadius || newRadius > this.maxHoldRadius) {
      return;
    }
    this.activeHold.radius = newRadius;
    // we will use this radius when creating a new hold.
    this._lastHoldRadiusUsed = newRadius;
    this.stage.batchDraw();
    this.notifyListener();
  }

  private zoomImage(point: Vector2d, scaleFactor: number) {
    const oldScaleFactor = this.stage.scaleX();
    // keep minimum zoom level such that image always touches at least two borders (top/bottom or left/right)
    if (this.image.width() * scaleFactor < this.stage.width() && this.image.height() * scaleFactor < this.stage.height()) {
      scaleFactor = this.isHorizontal
        ? this.stage.width() / this.image.width()
        : this.stage.height() / this.image.height();
    }

    // apply min/max zoom limits
    if (scaleFactor > maxZoom || scaleFactor < minZoom) {
      return;
    }

    const mousePoint = {
      x: (point.x - this.stage.x()) / oldScaleFactor,
      y: (point.y - this.stage.y()) / oldScaleFactor,
    };
    const newPos = {
      x: -(mousePoint.x - point.x / scaleFactor) * scaleFactor,
      y: -(mousePoint.y - point.y / scaleFactor) * scaleFactor
    };

    // fix position such that image always touches borders of widest extension
    if (this.isHorizontal) {
      const stageXRightMin = this.stage.width() - this.image.width() * scaleFactor;
      newPos.x = Math.min(50, newPos.x);
      if (this.stage.x() < stageXRightMin) {
        newPos.x = stageXRightMin;
      }
    } else {
      const stageYBottomMin = this.stage.height() - this.image.height() * scaleFactor;
      newPos.y = Math.min(0, newPos.y);
      if (this.stage.y() < stageYBottomMin) {
        newPos.y = stageYBottomMin;
      }
    }

    this.stage.scale({ x: scaleFactor, y: scaleFactor });
    this.stage.position(newPos);
    this.stage.batchDraw();
  }

  private disableActiveHold() {
    if (this.activeHold) {
      this.activeHold.setSettled();
      this.activeHold = undefined;
      this.currentHoldType = 'hold';
    }
  }

  setActiveHoldType(type: HoldType) {
    this.currentHoldType = type;
    if (!this.activeHold) {
      return;
    }
    this.activeHold.holdType = this.currentHoldType;
    this.enforceHoldTypeRestrictions();
    this.stage.batchDraw();
    this.notifyListener();
  }

  private createHold(holdCoordinate: HoldCoordinate): HoldElement {
    const circleHole = new Konva.Circle({
      x: holdCoordinate.centerX,
      y: holdCoordinate.centerY,
      radius: holdCoordinate.radius,
      fill: 'white',
      // we use this circle to 'cut a hole' into the masking rectangular
      globalCompositeOperation: 'destination-out'
    });

    const circleBorder = new Konva.Circle({
      x: holdCoordinate.centerX,
      y: holdCoordinate.centerY,
      shadowBlur: 30,
      radius: holdCoordinate.radius,
      strokeWidth: this.circleStrokeWidth,
    });

    const icon = new Icon(this.iconFontSize);

    const holdGroup = new Konva.Group({
      id: guid(),
    });
    holdGroup.add(circleHole, circleBorder, icon.iconGroup);
    holdGroup.on('click tap', ev => this.onHoldClicked(ev));
    holdGroup.on('dragend', () => this.notifyListener());

    const hold = new HoldElement(holdGroup, circleHole, circleBorder, icon);
    hold.radius = holdCoordinate.radius;
    hold.holdType = holdCoordinate.holdType;
    return hold;
  }

  private notifyListener() {
    this.holds = this.getCurrentHolds();
    this.onHoldsChanged.emit(this.holds);
  }

  private async addHoldAndMakeActive(): Promise<void> {
    const hold = await this.createHoldWhereClicked();
    this.activeHold = hold;
    this.holdElements.set(hold.group.id(), this.activeHold);
    this.service.maskAndHoldsGroup.add(hold.group);
    this.currentHoldType = 'hold';
    this.enforceHoldTypeRestrictions();
    this.notifyListener();
  }

  private async createHoldWhereClicked() {
    const pointer = this.stage.getPointerPosition();
    const scale = this.stage.scaleX();
    const centerX = (pointer.x - this.stage.x()) / scale;
    const centerY = (pointer.y - this.stage.y()) / scale;
    const detectHold = await this.holdDetectionService.detectHold(centerX, centerY, this.wallImage.imageId, this.image.width());
    if (detectHold) {
      const hold = this.createHold({
        centerX: detectHold.centerX,
        centerY: detectHold.centerY,
        radius: detectHold.radius,
        holdType: this.currentHoldType
      });
      hold.setActive();
      return hold;
    } else {
      const hold = this.createHold({ centerX: centerX, centerY: centerY, radius: this._lastHoldRadiusUsed, holdType: this.currentHoldType });
      hold.setActive();
      return hold;
    }

  }

  /**
   * There can be only one double-start hold, one top hold and two double-start holds.
   * This method will enforce these restriction by resetting hold types to 'hold' if required.
   */
  private enforceHoldTypeRestrictions() {
    const holdTypeChanged = this.activeHold.holdType;
    if (holdTypeChanged == 'single-start') {
      this.replaceHoldTypes('double-start', 'single-start');
    }
    if (holdTypeChanged == 'double-start') {
      this.replaceHoldTypes('single-start', 'hold');
    }
    if (holdTypeChanged == 'single-start' && this.getNumberOfHoldsWithType(holdTypeChanged) > 2) {
      // there is already more than one single start hold, we will make all but two a 'hold'.
      let counter = 0;
      const numSingleStartHoldsToReset = this.getNumberOfHoldsWithType(holdTypeChanged) - 2;
      for (const hold of Array.from(this.holdElements.values())) {
        if (counter < numSingleStartHoldsToReset && hold !== this.activeHold && hold.holdType == holdTypeChanged) {
          WallImageComponent.changeHoldType(hold, 'hold');
          counter++;
        }
      }
    } else if ((holdTypeChanged == 'double-start' || holdTypeChanged == 'top') &&
      this.getNumberOfHoldsWithType(holdTypeChanged) > 1) {
      this.replaceHoldTypes(holdTypeChanged, 'hold');
    }
  }

  private replaceHoldTypes(holdTypeToReplace: HoldType, replacement: HoldType) {
    for (const hold of Array.from(this.holdElements.values())) {
      if (hold.holdType == holdTypeToReplace) {
        WallImageComponent.changeHoldType(hold, replacement);
      }
    }
  }

  private getNumberOfHoldsWithType(holdType: HoldType) {
    let counter = 0;
    for (const hold of Array.from(this.holdElements.values())) {
      if (hold.holdType == holdType) {
        counter++;
      }
    }
    return counter;
  }

  ngOnDestroy(): void {
    this.pic.onload = () => {
    };
    this.pic.src = '';
    this.image?.destroy();
    this.stage?.destroy();
  }

}

class Icon {

  private readonly _label: Konva.Label;
  private readonly _firstStripe: Konva.Rect;
  private readonly _secondStripe: Konva.Rect;

  constructor(private iconFontSize) {
    this._label = new Konva.Label({
      x: 0,
      y: 0,
    });
    this._label.add(new Konva.Tag({
      // could be used as background if we need this
    }));
    this._label.add(new Konva.Text({
      text: '',
      fontFamily: 'FontAwesome',
      fontStyle: 'bold',
      fontSize: this.iconFontSize,
      fill: activeCircleColor
    }));
    this._label.on('click tap', e => {
      e.cancelBubble = true;
    });

    this._firstStripe = Icon.createStripe();
    this._firstStripe.rotate(45);
    this._firstStripe.x(44);
    this._firstStripe.y(19);

    this._secondStripe = Icon.createStripe();
    this._secondStripe.rotate(35);
    this._secondStripe.x(24);
    this._secondStripe.y(6);

    this._iconGroup.add(this._label, this._firstStripe, this._secondStripe);
  }

  private _holdType: HoldType;

  set holdType(holdType: HoldType) {
    this._holdType = holdType;
    this._label.removeChildren();
    this._label.add(new Konva.Text({
      text: this.getIconCode(),
      fontSize: this.iconFontSize,
      fill: settledCircleColor
    }));
    this._secondStripe.visible(holdType == 'single-start' || holdType == 'double-start');
    this._firstStripe.visible(holdType == 'double-start');
  }

  private _iconGroup = new Konva.Group({
    id: guid(),
  });

  get iconGroup(): Konva.Group {
    return this._iconGroup;
  }

  private static createStripe() {
    return new Konva.Rect({
      width: 10,
      height: 60,
      fill: activeCircleColor,
      strokeWidth: 0
    });
  }

  x(number: number) {
    this._iconGroup.x(number);
  }

  y(number: number) {
    this._iconGroup.y(number - 60);
  }

  fill(color: string) {
    this._firstStripe.fill(color);
    this._secondStripe.fill(color);
  }

  private getIconCode() {
    switch (this._holdType) {
      case 'hold':
      case 'double-start':
      case 'single-start':
      case 'foot':
        return '';
      case 'top':
        return 'TOP';
    }
  }
}

class HoldElement {
  constructor(readonly group: Konva.Group,
    readonly hole: Konva.Circle,
    readonly border: Konva.Circle,
    readonly icon: Icon) {
  }

  private _holdType: HoldType;

  get holdType(): HoldType {
    return this._holdType;
  }


  set holdType(holdType: HoldType) {
    this._holdType = holdType;
    this.icon.holdType = holdType;
    this.dashIfFoothold();
  }

  get center(): Vector2d {
    return {
      x: this.border.x() + this.group.x(),
      y: this.border.y() + this.group.y()
    };
  }

  get radius(): number {
    return this.border.radius();
  }

  set radius(radius: number) {
    this.hole.radius(radius);
    this.border.radius(radius);
    const offset = radius / sqrt2;
    this.icon.x(this.border.x() + offset);
    this.icon.y(this.border.y() - offset);
  }

  setActive() {
    this.group.draggable(true);
    this.border.stroke(activeCircleColor);
    this.border.shadowEnabled(true);
    this.border.shadowColor('white');
    this.icon.fill(activeCircleColor);
    this.dashIfFoothold();
  }

  setSettled() {
    this.group.draggable(false);
    this.border.stroke(settledCircleColor);
    this.border.shadowEnabled(false);
    this.icon.fill(settledCircleColor);
    this.dashIfFoothold();
  }

  dashIfFoothold() {
    if (this.holdType === 'foot') {
      this.border.dashEnabled(true);
      this.border.dash([5]);
    } else {
      this.border.dashEnabled(false);
    }
  }

}

interface HoldCoordinate {
  centerX: number;
  centerY: number;
  radius: number;
  holdType: HoldType;
}

class TouchState {
  hasMoved: boolean;
  lastPinchDist: number;
  startZoom: number;
  startPinchPoint: Vector2d;
  startPinchDist: number;
  startRadius: number;
}

function guid(): string {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}

function getDistance(p1: Vector2d, p2: Vector2d) {
  return Math.sqrt(Math.pow((p2.x - p1.x), 2) + Math.pow((p2.y - p1.y), 2));
}
