import {FootholdConfig, Hold} from '../common/docs';
import {WallImage} from './wall-image';
import {BoulderQuality} from './boulder-quality';
import {AscentListEntry} from './ascent-list-entry';
import {BoulderComment} from './boulder-comment';
import {BoulderListEntry} from './boulder-list-entry';
import {FootholdDetailsComponent} from '../components/foot-hold-details/foothold-details.component';
import { User } from './user';

export class Boulder extends BoulderListEntry {

  constructor(
    readonly wallName: string,
    readonly wallId: string,
    readonly wallImage: WallImage,
    readonly boulderName: string,
    readonly boulderId: string,
    readonly author: string,
    readonly holds: Hold[],
    readonly footholdConfig: FootholdConfig,
    readonly grade: number,
    readonly suggestedGradeByAuthor: number,
    readonly creationDate: Date,
    readonly quality: BoulderQuality,
    readonly ascents: AscentListEntry[],
    readonly comments: BoulderComment[],
    readonly ticked: boolean,
    readonly isPrivate: boolean,
    readonly isProject: boolean
  ) {
    super(boulderId, boulderId, author, grade, creationDate, quality.quality, wallName, ticked, ascents.length, FootholdDetailsComponent.isCampusBoulder(footholdConfig, holds), isPrivate, isProject);
  }
}
