<ion-header>
  <ion-toolbar>
    <ion-segment mode="md" *ngIf="!boulder || loading">
      <ion-label>
        <ion-spinner name="dots"></ion-spinner>
      </ion-label>
    </ion-segment>
    <ion-segment mode="md" [(ngModel)]="activeSegment">
      <ion-segment-button layout="icon-top" value="image">
        <ion-icon name="image"></ion-icon>
        <ion-label>{{"boulder-details-page.photo" | translate}}</ion-label>
      </ion-segment-button>
      <ion-segment-button layout="icon-top" value="ascents" data-cy="ascents-button">
        <ion-icon name="checkmark-circle"></ion-icon>
        <ion-label>{{"boulder-details-page.ascents" | translate}}</ion-label>
        <ion-badge class="badge" *ngIf="boulder?.ascents.length>0">{{ boulder.ascents.length }}</ion-badge>
      </ion-segment-button>
      <ion-segment-button layout="icon-top" value="comments">
        <ion-icon name="chatbubbles-outline"></ion-icon>
        <ion-label>{{"boulder-details-page.comments" | translate}}</ion-label>
        <ion-badge class="badge" *ngIf="boulder?.comments.length>0">{{ boulder.comments.length }}</ion-badge>
      </ion-segment-button>
      <ion-segment-button layout="icon-top" (click)="close()" data-cy="close-button"  id="close-button">
        <ion-icon name="close"></ion-icon>
        <ion-label>{{"boulder-details-page.close" | translate}}</ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
</ion-header>
<ion-content *ngIf="boulder">
  <ion-fab *ngIf="activeSegment=='image' && boulderIds?.indexOf(boulderId)>0" horizontal="start" slot="fixed"
           vertical="bottom">
    <ion-fab-button translucent="true" class="fabButton" (click)="previousBoulder()">
      <ion-icon name="arrow-back"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <ion-fab *ngIf="activeSegment=='image' && boulderIds?.indexOf(boulderId)<boulderIds?.length-1" horizontal="end"
           slot="fixed" vertical="bottom">
    <ion-fab-button translucent="true" class="fabButton" (click)="nextBoulder()">
      <ion-icon name="arrow-forward"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <div *ngIf="activeSegment=='image'">
    <ion-fab *ngIf="imageFullscreenMode" horizontal="end" slot="fixed" vertical="top">
      <ion-fab-button (click)="imageFullscreenMode=false" closeIcon="close" translucent="true" class="fabButton">
        <ion-icon name="information-circle-outline"></ion-icon>
      </ion-fab-button>
    </ion-fab>
    <div [ngClass]="imageFullscreenMode ? 'in-back' : 'in-front'" id="boulder-info">
      <p [routerLink]="'/profile/'+boulder.author" (click)="close()"
        [innerHTML]='"boulder-details-page.a-boulder-by" | translate:{author: boulder.author, wallName: boulder.wallName}'>
      </p>
      <h1> {{boulder.boulderName}}
        <ion-chip (click)="gradeClicked()" class="grade">
          <ion-label>{{boulder.grade|boulderGrade}}</ion-label>
        </ion-chip>
        <ion-chip (click)="editBoulder()" *ngIf="isAllowedToEdit">
          <ion-icon color="danger" name="create"></ion-icon>
          <ion-label color="danger">{{"boulder-details-page.edit" | translate}}</ion-label>
        </ion-chip>
        <ion-chip *ngIf="boulder.wallImage.currentness!='current'">
          <ion-icon color="danger" name="ban"></ion-icon>
          <ion-label color="danger">{{"boulder-details-page.unscrewed" | translate}}</ion-label>
        </ion-chip>
        <ion-chip *ngIf="boulder.quality.numThumbsUp>0" class="good-boulder" slot="start">
          <ion-label>{{boulder.quality.numThumbsUp}}</ion-label>
          <ion-icon class="good-boulder" name="thumbs-up"></ion-icon>
        </ion-chip>
        <ion-chip *ngIf="boulder.quality.numThumbsDown>0" class="bad-boulder" slot="start">
          <ion-label>{{boulder.quality.numThumbsDown}}</ion-label>
          <ion-icon class="bad-boulder" name="thumbs-down"></ion-icon>
        </ion-chip>
        <foothold-details [boulder]="boulder"></foothold-details>
        <ion-chip slot="start" class="draft-chip" *ngIf="boulder?.isPrivate">
          <ion-label>{{"boulder-list-entry-component.draft" | translate}}</ion-label>
        </ion-chip>
        <ion-chip slot="start" class="share-chip" (click)="share()">
          <ion-label>{{"boulder-details-page.share-it" | translate}}</ion-label>
          <ion-icon name="share-social-outline"></ion-icon>
        </ion-chip>
        <ion-chip *ngIf="!boulder.isProject && !boulder.ticked" slot="start" class="add-to-list-chip" (click)="addToProjects()">
          <ion-label>{{"boulder-details-page.add-to-projects" | translate}}</ion-label>
          <ion-icon name="add-circle-outline"></ion-icon>
        </ion-chip>
        <ion-chip *ngIf="boulder.isProject && !boulder.ticked" slot="start" class="add-to-list-chip">
          <ion-label>{{"boulder-details-page.your-project" | translate}}</ion-label>
          <ion-icon name="hammer-outline"></ion-icon>
        </ion-chip>
        <ion-chip *ngIf="boulder.ticked" slot="start" class="ticked-chip">
          <ion-label>{{"boulder-list-entry-component.ticked" | translate}}</ion-label>
          <ion-icon name="checkmark-circle-outline"></ion-icon>
        </ion-chip>
        <ion-chip slot="start" class="add-to-list-chip" (click)="addToList()">
          <ion-label>{{"boulder-details-page.add-to-list" | translate}}</ion-label>
          <ion-icon name="list-circle-outline"></ion-icon>
        </ion-chip>
      </h1>
    </div>
    <app-wall-image (click)="toggleImageFullscreenMode()" (onImageTouched)="toggleImageFullscreenMode()"
                    [editMode]="false" [holds]="boulder.holds" [wallImage]="boulder.wallImage"></app-wall-image>
  </div>


  <div *ngIf="activeSegment=='ascents'" class="ion-padding">
    <p *ngIf="getAscentForUser() as ascent ; else noAscent" class="ascent-text" slot="start">
      {{(ascent.flash ? "boulder-details-page.you-flashed-it" : "boulder-details-page.you-sent-it") | translate}}
      <span (click)="removeAscent()"><br><u>{{"boulder-details-page.or-not" | translate}}</u></span>
    </p>
    <ng-template #noAscent>
      <ion-button [hidden]="loading" (click)="sentIt()" data-cy="sent-it-button" expand="full">
        <ion-icon name="checkbox" slot="start"></ion-icon>
        {{"boulder-details-page.sent-it" | translate}}
      </ion-button>
      <loader *ngIf="loading"></loader>
    </ng-template>
    <ascent-list #ascentsList [ascents]="boulder.ascents"></ascent-list>
  </div>
  <div *ngIf="activeSegment==='comments'" class="ion-padding">
    <app-boulder-comments [boulderId]="boulderId" [comments]="boulder.comments"></app-boulder-comments>
  </div>
</ion-content>
<loader *ngIf="!boulder && activeSegment==='image'"></loader>
