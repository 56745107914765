import { Component } from '@angular/core';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { AppLauncher } from '@capacitor/app-launcher';
import { Browser } from '@capacitor/browser';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar } from '@capacitor/status-bar';
import { AlertController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BBStorage } from '../utils/bb-storage';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {

  constructor(
    private screenOrientation: ScreenOrientation,
    private alertCtrl: AlertController,
    private translate: TranslateService,
    private platform: Platform,
    private bbStorage: BBStorage
  ) {
    this.platformReady();
  }

  platformReady() {
    this.platform.ready().then(() => {
      if (this.isCapacitor()) {
        SplashScreen.hide();
        if (!this.isIos()) {
          StatusBar.setOverlaysWebView({ overlay: false });
          StatusBar.setBackgroundColor({ color: '#ef4b89' });
          this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
        }
        this.showSupportMessage();
      }
    });
  }

  private isCapacitor() {
    return this.platform.is('capacitor');
  }

  private isIos() {
    return this.platform.is('ios') || this.platform.is('iphone');
  }

  private async showSupportMessage() {
    if (this.bbStorage.instagramMessageShown) {
      return;
    }
    this.bbStorage.instagramMessageShown = true;
    await (await this.alertCtrl.create({
      header: this.translate.instant('app-component.do-you-like-boulderbook'),
      message: this.translate.instant('app-component.support-boulderbook'),
      cssClass: 'bb-alert',
      buttons: [{ text: this.translate.instant('app-component.later'), role: 'cancel', cssClass: 'cancel-button' }, {
        text: this.translate.instant('app-component.yes'),
        cssClass: 'yes-button',
        handler: (value: any) => this.navigateToBBInsta()
      }]
    })).present();
  }

  private async navigateToBBInsta() {
    await this.instagramAppInstalled() ?
      Browser.open({ url: 'instagram://user?username=boulder.book' }) :
      Browser.open({ url: 'https://www.instagram.com/boulder.book' });
    return true;
  }

  private async instagramAppInstalled() {
    return (await AppLauncher.canOpenUrl({ url: 'instagram://' })).value;
  }
}
