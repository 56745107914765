import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import * as Sentry from '@sentry/angular';
import { captureConsoleIntegration } from '@sentry/integrations';
import AppRate from 'cordova-plugin-apprate';
import { MatomoTracker } from 'ngx-matomo-client';
import { environment } from 'src/environments/environment';
import { BBErrorType, SchemaUpdateError } from '../errors/bb-error';
import { DeepLinksService } from '../services/deep-links.service';
import { ErrorNotificationService } from '../services/error-notification.service';
import { GuestService } from '../services/guest-service';
import { RestService } from '../services/rest.service';
import { TranslationService } from '../services/translation.service';
import { ValidationService } from '../services/validation.service';
import { VersionService } from '../services/version.service';
import { BBStorage } from '../utils/bb-storage';

/**
 * Here we can initialize services that need to do async work when the app starts
 */
@Injectable()
export class AppInitializer {
  constructor(
    private matomoTracker: MatomoTracker,
    private platform: Platform,
    private versionService: VersionService,
    private guestService: GuestService,
    private bbStorage: BBStorage,
    private storage: Storage,
    private restService: RestService,
    private validationService: ValidationService,
    private errorHandler: ErrorNotificationService,
    private deepLinksService: DeepLinksService,
    private translate: TranslationService) {
  }


  public async run() {
    await this.storage.create();
    if (await this.makeSureMajorVersionUpToDate()) {
      await this.bbStorage.initialize();
      await this.translate.initialize();
      await this.updateSchema();
      await this.initValidation();
      await this.deepLinksService.initialize();
      if (this.platform.is('capacitor') && environment.production) {
        this.initializeAppRatePlugin();
        this.initializeAnalytics();
        this.initializeErrorTracking();
      }
    }
  }


  private initializeAppRatePlugin() {
    if (this.guestService.isGuest() || !this.platform.is('capacitor')) {
      return;
    }
    AppRate.setPreferences({
      useLanguage: this.translate.getCurrentLanguage(),
      reviewType: {
        ios: 'AppStoreReview',
        android: 'InAppBrowser'
      },
      promptAgainForEachNewVersion: false,
      displayAppName: 'BoulderBook',
      usesUntilPrompt: 30,
      storeAppURL: {
        ios: 'com.boulder-book.app',
        android: 'market://details?id=com.boulderbook'
      },
      callbacks: {
        handleNegativeFeedback: () => {
          window.open('mailto:feedback@boulder-book.com', '_system');
        }
      }
    });
    AppRate.promptForRating(false);
  }

  private async updateSchema() {
    if (!this.bbStorage.schemaAvailable || !await this.versionService.versionUpToDate()) {
      try {
        await this.restService.updateSchema();
      } catch (error) {
        // we need to make sure the schema is available in storage or otherwise throw the error
        if (!this.bbStorage.schemaAvailable || error.bbErrorType != BBErrorType.NO_INTERNET_CONNECTION) {
          await this.errorHandler.showBBError(new SchemaUpdateError(error));
        }
      }
    }
  }

  private async makeSureMajorVersionUpToDate(): Promise<Boolean> {
    if (!await this.versionService.majorVersionUpToDate()) {
      await this.errorHandler.showDeprecatedAppVersion();
      return false;
    } else {
      return true;
    }
  }

  private async initValidation() {
    await this.validationService.initialize();
  }

  public async runForTest() {
    await this.storage.create();
    await this.bbStorage.clear();
    const couchSchema = require('../../../server/src/schema/couch_schema.json');
    const restSchema = require('../../../server/src/schema/rest_schema.json');
    const typeDefinitions = require('../../../server/src/schema/type_definitions.json');
    await this.bbStorage.initialize();
    await this.bbStorage.setSchema(couchSchema, restSchema, typeDefinitions);
    await this.initValidation();
  }

  private initializeAnalytics() {
    this.matomoTracker.setUserId(this.bbStorage?.userSession?.user_name);
    this.matomoTracker.disableCookies();
    this.versionService.getAppVersion()
      .then(version => this.matomoTracker.trackEvent('version', 'init-app', version));
  }

  private async initializeErrorTracking() {
    const release = await this.versionService.getAppVersion();
    Sentry.init({
      dsn: 'https://2b594ea9359e0df168fef0d75b0dc054@o4504106445701120.ingest.sentry.io/4506574560690176',
      release: release,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.captureConsoleIntegration({
          levels: ['error']
        })
      ],
      environment: 'production',
      tracesSampleRate: 0.25,
      tracePropagationTargets: ['localhost'],
    });
  }
}
