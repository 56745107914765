import {Injectable} from '@angular/core';
import {BBLoginService} from './bblogin.service';
import {UserSession} from '../model/user-session';
import {LoginDto, NewUserDto} from '../common/dtos';

export const GUEST_USERNAME = 'guest';
export const GUEST_PSW = 'guestpsw';

@Injectable()
export class AuthService {

  constructor(private loginService: BBLoginService) {
  }

  /**
   * Loads the current username synchronously from local storage, without talking to the server
   */
  public getCurrentUsername(): string {
    return this.loginService?.currentSession?.user_name;
  }

  public isAdmin() {
    const user = this.getCurrentUsername();
    return user == 'flo' || user == 'bbadmin';
  }

  public isBetaTester() {
    const user = this.getCurrentUsername();
    return user == 'dax' || user == 'LilP' || user == 'andi' || user == 'JohnnySins';
  }

  /**
   * Loads the current user session synchronously from local storage, without talking to the server
   */
  public getCurrentUserSession(): UserSession {
    return this.loginService.currentSession;
  }

  /**
   * Checks if there is a session token stored locally, without talking to the server
   */
  public isLoggedIn() {
    return this.loginService.isLoggedIn();
  }

  public async signup(user: NewUserDto) {
    await this.loginService.register(user);
  }

  public async login(credentials: LoginDto) {
    await this.loginService.login(credentials);
  }

  public async logout() {
    await this.loginService.logout();
  }
}

